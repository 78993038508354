<template>
  <div>
    <img v-lazy="lazyLogo" class="logoWrapper" />
    <h1>Rechnung zahlen</h1>
    <p>Bitte gebe deine Rechnungsnummer ein.</p>
    <div class="d-flex flex-row justify-content-center">
      <b-input
        v-model="invoiceYear"
        :no-wheel="true"
        type="number"
        ref="invoiceYear"
        @update="updateYear($event)"
        trim
        placeholder="YYYY"
        style="width: 8ch; text-align: center"
      />
      <span style="width: 1ch" />
      <b-input
        v-model="invoiceNumber"
        :no-wheel="true"
        type="number"
        ref="invoiceNumber"
        @update="updateNumber($event)"
        trim
        placeholder="KKKRRR"
        style="width: 10ch; text-align: center"
      />
    </div>
    <b-button
      @click="next"
      ref="next"
      variant="primary"
      :disabled="!valid"
      style="margin-top: 1rem"
      >Weiter</b-button
    >
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      invoiceYear: null,
      invoiceNumber: null,
      lazyLogo: {
        src: "logo.png",
        loading: "logo-min.png",
      },
    };
  },
  methods: {
    next() {
      this.$router.push({
        name: 'Token',
        params: {
          invoiceYear: this.invoiceYear,
          invoiceNumber: this.invoiceNumber,
        },
      });
    },
    updateYear(val) {
      if (val.length > 3) this.$refs.invoiceNumber.focus();
      // v-on:keyup.enter="$event.target.nextElementSibling.focus()"
    },
    updateNumber(val) {
      if (val.length > 5)
        setTimeout(() => {
          this.$refs.next.focus();
        }, 100);
    },
  },
  computed: {
    valid() {
      const valid =
        this.invoiceYear &&
        this.invoiceNumber &&
        this.invoiceYear.length === 4 &&
        this.invoiceNumber.length === 6 &&
        !isNaN(this.invoiceYear) &&
        !isNaN(this.invoiceNumber);
      return valid;
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>