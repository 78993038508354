import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router/router.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)


Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://legacy.itmr.dev/api/'

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    toast: function (title, text, variant = null) {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        appendToast: false
      })
    },
    error: function (error) {
      router.push({
        name: 'Error',
        params: {
          error,
        }
      });
    }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
